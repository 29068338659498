/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  backBtn: 'Back to overview',
  teaserTitle: 'Get a <b>Quickstart</b> live demo',
  teaserContent: "You want to know more? We'd be happy to introduce you to all of the features of TravelSandbox® Quickstart. Let us find out together how TravelSandbox® Quickstart can be adapted to your specific needs. Contact us to arrange an appointment.",
  teaserBtn: 'Request demo now',
  quickstartTeaserText: 'Join the future of an even more cost-effective and faster online travel market with TravelSandbox® Quickstart! A complete, flexible and robust sales platform for the online travel market, ready for expansion and customisation.',
  btnText: 'Check it out',
  valueTeaserTitle: 'Manage your content easier than ever',
  valueTeaserContent: 'With the native support of our freshMS you are able to manage your on page marketing activities in a convinient way without the need of confusing CMS backends. Benefit from live prices and availabilities for your offers and schedule your promotions as you like.',
  videoTeaserTitle: 'Quickstart at a glance',
  valueTeaserBtnText: 'Learn more about the freshMS',
  quickstartValueBoxTitle1: 'Complete sales platform',
  quickstartValueBoxTitle2: 'Optimal customisation',
  quickstartValueBoxTitle3: 'Outstanding <br/>price-performance ratio',
  descriptionTeaserTitle: 'Get a',
  descriptionTeaserContent: 'Contact us to learn how Quickstart and the TravelSandbox® can enrich your business.',
  descriptionTeaserBtn: 'Get in touch now',
  infographicTitleQuickstart: '<b>Complete online sales platform</b> for travel companies',
  infographicText: '<b>The TravelSandbox® Quickstart is more than an internet booking engine. </b>{br}{br}It is your comprehensive sales platform! Including marketing pages, destination pages, landing pages, product information pages, booking section, corporate website and more. {br}{br}TravelSandbox® Quickstart supports multi-language, multi-currency, multi-market and multi-brand. {br}{br}It is the central point of contact for your customers for every destination and source market. You can freely choose your interfaces with no limits on new business models. Optimum usability for your marketing and sales campaigns.',
  labelName: 'Your name',
  labelFreetext: 'Your message',
  placeholderName: 'Name *',
  labelCompany: 'Your company',
  placeholderCompany: 'Company *',
  labelMail: 'Your e-mail address',
  placeholderMail: 'E-Mail *',
  placeholderFreetext: 'What are you curious about ...',
  checkboxText: "I accept the '<a href=\"/en/privacy-policy/\" target=\"_blank\">privacy policy</a>' *",
  labelButton: 'Request demo now',
  requiredTextInput: 'Please fill in this field',
  requiredCheckbox: 'Please tick this box if you want to proceed',
  successModalText: 'Thanks for your request',
  successModalTitle: 'We will get back to you as soon as possible',
  successModalBtn: 'Learn more about Quickstart',
  teasermodaltitle: 'Request a <b>live demonstration</b> for <b>Quickstart</b>',
  featurelistModaltitle: 'Request the <b>detailed feature list</b> for <b>Quickstart</b>',
  featurelistSuccessModalTitle: 'Your Quickstart feature list is ready to Download',
  featurelistSuccessModalText: 'We are looking forward to answer your questions.',
  featurelistModalDownloadBtn: 'Download feature list',
  featurelistModalLabelButton: 'Request feature list',
  CaseLogosTitle: 'Buy in expertise',
  CaseLogosSubtitle: 'To date, we have developed and published more than 100 online travel projects. We have accumulated thousands of hours of experience. TravelSandbox® Quickstart is the culmination of all this.',
  theQuickstartTitle: 'The Quickstart<b>!</b>',
  theQuickstartDescription: 'We have <b>reduced the time to market</b> even further! The TravelSandbox® Quickstart is your comprehensive One-Platform solution. ',
  getEverythingTitle: 'Get everything<b>!</b>',
  getEverythingDescription: '<b>Homepage, landing pages, online store...</b> All processes have to be orchestrated with each other. Or you can use the Quickstart. One Platform for all!',
  scaleUpTitle: 'Scale Up<b>!</b>',
  scaleUpDescription: 'Even the longest journey starts with the first step. With Quickstart you are quickly online and ready to go. And you remain flexible. <b>Whether new supply sources, new brands or innovative online sales mechanisms.</b> Quickstart grows with you. ',
  featureListTitle: 'List of features',
  featureListDescription: 'All features in detail for download',
  featureListDownloadBtn: 'Download now',
  featuresTitle: '<b>Digitalize your processes now</b> and be ready for new demand in the post-pandemic period',
  featuresSubtitle: 'We are aware that the Covid-19 pandemic impacted a lot and slowed down tourism; Thinking about new software investment now will help you be prepared for the rebound of tourism and new demand in the future. Choosing the right software solution can kickstart the growth of your company.',
  featurelist01Title: 'Modern user interface',
  featurelist01listItem01: '<b>Mobile First </b><br/>All user interfaces work on smartphones at least as well as they do on desktop PCs.',
  featurelist01listItem02: '<b>Optimum usability </b><br/>Make the booking process faster for your customers.',
  featurelist01listItem03: '<b>One page, one Experience </b><br/>Minimum loading times; micro animations support the desired user journey.',
  featurelist02Title: 'Full flexibility',
  featurelist02listItem01: '<b>Out of the Box </b><br/>TravelSandbox® Quickstart is your comprehensive online sales platform. Your leading online sales system from the get-go.',
  featurelist02listItem02: '<b>Flexible upgrading </b><br/>Anything you absolutely need? We build** it for you, either for launch or at a later time.',
  featurelist02listItem03: '<b>It’s all about branding </b><br/>Logo, colors, fonts, usability and more. Your platform can have it all!',
  featurelist02Footnote: '** Customer-specific customisations are not included in the offer but are available. Ask our sales experts.',
  featurelist03Title: 'Superior architecture',
  featurelist03listItem01: '<b>Modular instead of monolithic </b><br/>Logically separated systems for backend, middleware and frontend, with clearly structured communication layers.',
  featurelist03listItem02: '<b>Independent, not constricted </b><br/>Fusion® integrates diverse products and data in your TravelSandbox® Quickstart.',
  featurelist03listItem03: '<b>My Geo-Tree, my decision </b><br/>You decide which products are categorised and how, regardless of how the data was previously structured.',
  featurelist03Footnote: '*** Fusion® can technically also query multiple quote sources in parallel and process quotes from multiple sources in parallel. Depending on the contract terms of the quote source, this option may not be available to you.',
  featurelistButton: 'Tell me more',
  pricingTitle: 'Features & Price <br/><b>Lease now</b>',
  pricingSubtitle: "Regardless of the Corona virus, the future of package tours sales is online! Travel providers and travel agents don't just need any Internet Booking Engine – they need a powerful sales platform if they want to keep up. <br/><br/>freshcells now offers the complete solution for online travel sales at a fixed price. <br/><br/>Also for leasing: In cooperation with Dusseldorf-based LeasAG, freshcells makes the advantages of platform technology accessible to everyone.",
  featuresOverviewTitle: '<b>Start now</b> and be online in less than 2 months',
  featuresOverviewSubtitle: 'This much is certain: even the strictest lockdown is going to end at some point. And experience shows that people will travel whenever possible. If you wish to profit from the expected wave of bookings, get your sales activities "ready for take-off"!',
  featuresOverviewFootnote: '* All application texts available in English and German, more languages can be added.',
  moreFeaturesOverviewTitle: 'Options for <b>even more optimization</b>',
  feature01: 'Flexible product source connection',
  feature02: 'Stand-alone platform',
  feature03: 'One-Page Application',
  feature04: 'Cloud-based infrastructure',
  feature05: 'Modern, dynamic frontends',
  feature06: '100% Branding',
  feature07: '100% localisable *',
  feature08: 'Google Tag Manager Integration',
  feature09: 'Third party booking systems integrable',
  moreFeature01: 'White labelling',
  moreFeature02: 'Upselling',
  moreFeature03: 'Individual Content',
  moreFeature04: 'Native app variants',
  moreFeature05: 'Market-specific features possible',
  moreFeature06: 'Integration of marketing campaigns',
  moreFeature07: 'One-stop cloud hosting',
  moreFeature08: "Frontend editor for marketing based on '<a href=\"https://www.freshcells.de/products/freshms/\" target=\"_blank\" rel=\"noopener noreferrer\">freshMS</a>'",
  moreFeature09: 'Localisation for non-Latin scripts',
  pricingTableTitle: 'Quickstart One-Platform Leasing',
  pricingTablePrice: '4.390€',
  pricingTableDescription: 'Lease a ready-made One-Platform Solution including IBE at a reasonable price. Additional features and services optional.',
  pricingTableListItem01: 'Connection to a product system {br}(Peakwork Player-Hub, Amadeus Leisure, Bewotec)',
  pricingTableListItem02: '100% branded stand-alone frontend with individual features',
  pricingTableListItem03: 'Google Tag Manager integration',
  pricingTableListItem04: 'Connection of a non-bookable content system (GIATA)',
  pricingTableListItem05: 'Marketing-ready with editable page elements',
  pricingTableListItem06: 'Marketing and content page management',
  pricingTableListItem07: 'Filters for results lists',
  pricingTableListItem08: 'Offer details pages with deeplink support',
  pricingTableListItem09: 'Integration of a booking system (Peakwork, Amadeus, Bewotec)',
  pricingTableBadge: 'Special offer',
  pricingTablePriceInfoTitle: 'Monthly payment plan',
  pricingTablePriceInfo: 'The monthly instalment rate and one-off down payment (below) are an example calculation of an implementation within the setup described: {br}{br}Down payment (one-off): from €15,750, 36-month term, all prices plus VAT at the statutory rate. Additional features ("Options for even more optimization") and third-party services not included. Hosting possible at extra charge. Price dependent on creditworthiness, subject to verification by LeasAG. The price quoted is based on the described setup, a creditworthiness verification and a corresponding offer from the leasing company. For more information, please contact our sales experts. {br}{br}Please note that Quickstart does not include a license agreement for an offer source, booking or payment system. To set up and operate your own Quickstart platform, you will need appropriate license agreements.',
  NavOverview: 'Overview',
  NavVideo: 'Video',
  NavFeatures: 'Features',
  NavInfographic: 'Infographic',
  NavPlatform: 'Platform',
  NavBenefits: 'Benefits',
  NavFeatureOverview: 'Feature Overview',
  NavPricing: 'Pricing',
  NavDemo: 'Demo',
  NavReferences: 'References'
})
