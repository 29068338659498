/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: '<b>Complete online sales platform</b> for travel companies',
  contentText: '<b>The TravelSandbox® Quickstart is more than an internet booking engine. </b>{br}{br}It is your complete sales platform. Including marketing pages, destination pages, landing pages, product information pages, booking section, corporate website, etc. {br}{br}TravelSandbox® Quickstart is multi-language, multi-currency, multi-market and multi-band. {br}{br}It is your customers single point of contact for any destination and source market. You are free to choose your interfaces, with no limits for new business models. Optimal usability for your marketing and sales campaigns.',
  subtitle: 'Possible sources',
  icon4Label: 'Any other'
})
